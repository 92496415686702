import React from "react";
import styled from "@emotion/styled";
import {
  Grid,
  List,
  ListItemButton as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
} from "@mui/material";

interface ListItemButtonProps extends MuiListItemButtonProps {
  component?: React.ElementType; // Allow component to be any valid React component type
  href?: string;
}

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps>`
  display: inline-block;
  width: auto;

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

function Footer() {
  return (
    <Wrapper className="bg-[#F9F9F9] p-0">
      <Grid
        container
        spacing={0}
        height={108}
        flex="flex"
        alignItems="center"
        paddingLeft="83px"
      >
        <Grid item xs={12} md={6}>
          <List className="p-0">
            <ListItemButton
              component="a" // Now properly typed as React.ElementType
              href="/"
              sx={{
                padding: "unset",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <img src="/static/img/nokia_logo.jpg" alt="logo" />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
