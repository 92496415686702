import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import useAuth from "../../hooks/useAuth";

import NavbarUserDropdown from "./NavbarUserDropdown";

import {
  Grid,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  ListItemButton,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

type NavbarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
  isShowLoginUI?: boolean;
};

const Navbar: React.FC<NavbarProps> = ({
  onDrawerToggle,
  isShowLoginUI = true,
}) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/auth/sign-in");
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar className="px-[83px]">
          <Grid container alignItems="center">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent={"center"}
                alignItems={"center"}
              >
                <ListItemButton
                  component="a"
                  href="/"
                  className="p-0 pr-4 border-r border-solid border-[#EBEBEB] hover:bg-transparent"
                >
                  <img
                    src="/static/img/brands/logo.svg"
                    alt="logo"
                    className="w-[102px]"
                  />
                </ListItemButton>
                <Typography variant="h5" className="pl-4 text-black">
                  Partner Rebate
                </Typography>
              </Grid>
            </Grid>
            {isShowLoginUI && (
              <>
                <Grid item xs />
                <Grid item>
                  {isAuthenticated ? (
                    <NavbarUserDropdown />
                  ) : (
                    <Link
                      component="button"
                      underline="none"
                      className="text-[#005AFF] text-base"
                      onClick={handleLogin}
                    >
                      Login
                    </Link>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
