import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import { removeAllCookies } from "../../utils/cookies";

import {
  Tooltip,
  Menu,
  MenuItem,
  Avatar,
  Box,
  Typography,
  Grid,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const navigate = useNavigate();
  const { signOut, user } = useAuth();
  const location = useLocation();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = () => {
    removeAllCookies();
    signOut();

    // navigate("/auth/sign-in");
  };
  const isProfileActivePage = location.pathname === "/my-profile";
  const isDashboardActivePage = location.pathname === "/dashboard";
  return (
    <React.Fragment>
      <Tooltip title="Account">
        <>
          {user && (
            <>
              <Grid
                container
                direction={"row"}
                columnSpacing={4}
                alignItems={"center"}
              >
                <Grid item xs={8}>
                  <Typography variant="caption" className="text-black">
                    {user.name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Avatar
                    alt={user.name}
                    src={user.avatar}
                    aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                    className="hover: cursor-pointer"
                  />
                </Grid>
              </Grid>
            </>
          )}
        </>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <Box
          display="flex"
          justifyContent="center"
          className="pt-2 px-4 pb-4 h-[74px]"
        >
          <Grid container>
            <Grid item className="w-full">
              <Grid
                container
                direction="row"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <MenuItem
                    onClick={() => {
                      navigate("/my-profile");
                      closeMenu();
                    }}
                    className="hover:bg-transparent"
                  >
                    <Grid
                      container
                      justifyContent={"center"}
                      direction={"column"}
                      alignItems={"center"}
                      className={`transition-all duration-300 ${
                        isProfileActivePage
                          ? "text-nokia-blue"
                          : "bg-transparent hover:text-nokia-blue"
                      }`}
                    >
                      <PersonOutlineOutlinedIcon />
                      <Typography variant="body1">My Profile</Typography>
                    </Grid>
                  </MenuItem>
                </Grid>
                <Grid item>
                  <MenuItem
                    onClick={() => {
                      navigate("/dashboard");
                      closeMenu();
                    }}
                    className="hover:bg-transparent"
                  >
                    <Grid
                      container
                      justifyContent={"center"}
                      direction={"column"}
                      alignItems={"center"}
                      className={`transition-all duration-300 ${
                        isDashboardActivePage
                          ? "text-nokia-blue"
                          : "bg-transparent  hover:text-nokia-blue"
                      }`}
                    >
                      <WidgetsOutlinedIcon />
                      <Typography fontSize={16} fontWeight={300}>
                        My Dashboard
                      </Typography>
                    </Grid>
                  </MenuItem>
                </Grid>
                <Grid item>
                  <MenuItem
                    onClick={handleSignOut}
                    className="hover:bg-transparent"
                  >
                    <Grid
                      container
                      justifyContent={"center"}
                      direction={"column"}
                      alignItems={"center"}
                      className="hover:text-[#005AFF]"
                    >
                      <PowerSettingsNewOutlinedIcon />
                      <Typography fontSize={16} fontWeight={300}>
                        Logout
                      </Typography>
                    </Grid>
                  </MenuItem>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
