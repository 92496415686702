import "@mui/lab/themeAugmentation";

import { createTheme as createMuiTheme } from "@mui/material/styles";
import variants from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";
import { colors } from "./variables";

const createTheme = (name: string) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      // @ts-ignore
      components: {
        ...components,
        MuiBreadcrumbs: {
          styleOverrides: {
            // root: {
            //   color: "black",
            // },
            ol: {
              "& .MuiLink-root": {
                color: colors.nokiaBlue, // Set breadcrumb link color here
                // "&:hover": {
                //   color: "#ff7043", // Set hover color for links
                // },
              },
            },
          },
        },
      },
      typography: typography,
      shadows: shadows,
      palette: themeConfig.palette,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    }
  );
};

export default createTheme;
