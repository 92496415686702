import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import ErrorLayout from "./layouts/Error";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

import Profile from "./pages/pages/Profile";
import PublicPage from "./pages/dashboards/MyDashboard/PublicPage";

//Home Page
const Home = async(() => import("./pages/homePage/index"));

// Protected routes
const SignInCallback = async(() => import("./pages/auth/SignInCallback"));
// import { Navigate } from "react-router-dom";
const MyDashboard = async(() => import("./pages/dashboards/MyDashboard"));
const DashboardPage = async(
  () => import("./pages/dashboards/MyDashboard/DashboardPage")
);

const routes = [
  {
    path: "/",
    element: (
      // <AuthGuard>
      <DashboardLayout />
      // </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <MyDashboard />,
      },
      {
        path: ":dashboardName",
        element: <DashboardPage />,
      },
    ],
  },
  {
    path: "public",
    element: <DashboardLayout isShowLoginUI={false} />,
    children: [
      {
        path: "",
        element: <PublicPage />,
      },
      {
        path: ":id/content",
        element: <PublicPage />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "callback",
        element: <SignInCallback />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "my-profile",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Profile />,
      },
    ],
  },
  {
    path: "*",
    element: <ErrorLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
