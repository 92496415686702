import React, { useEffect, useRef } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import { MB_SITE_URL } from "../../../constant";

const PublicPage: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { id } = useParams<{ id: string }>();
  const queryParams = new URLSearchParams(useLocation().search);
  let iframeUrl = queryParams.get("src");

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;

      iframe.onload = () => {
        if (window.iFrameResize) {
          (window as any).iFrameResize({}, iframe);
        }
      };
    }
  }, []);

  if (!iframeUrl) {
    return <Navigate to="/404" replace />;
  }

  iframeUrl = `${MB_SITE_URL}${iframeUrl}`;

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          className="px-[83px] bg-white py-6 text-left"
        >
          {`${capitalizeFirstLetter(id || "")} Dashboard`}
        </Typography>
        <Box className="px-12 flex flex-col justify-center items-center flex-grow">
          <iframe
            ref={iframeRef}
            src={iframeUrl}
            className="border-0, w-full"
            title={id}
          />
        </Box>
      </Box>
    </>
  );
};

export default PublicPage;
