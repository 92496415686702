import { createContext, ReactNode, useEffect, useReducer } from "react";

import { ActionMap, AuthState, AuthUser, OidcContextType } from "../types/auth";
import { oidcConfig } from "../config";
import { UserManager } from "oidc-client-ts";
import { isValidToken, setSession } from "../utils/jwt";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { OPENID_SITE_URL } from "../constant";

export const userManager = new UserManager(oidcConfig);

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_IN]: {
    user: AuthUser;
  };
  [SIGN_OUT]: undefined;
};

type OidcActions = ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>];

const OpenIdConnectReducer = (state: AuthState, action: OidcActions) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

const AuthContext = createContext<OidcContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(OpenIdConnectReducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window?.localStorage?.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          const response = await axios.get(`${OPENID_SITE_URL}/api/user-info`);
          const user = response.data;

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        if (userManager) {
          setSession(null);
          await userManager.signoutRedirect();
        }
      }
    };

    initialize();
  }, []);

  const signIn = async () => {
    try {
      await userManager.signinRedirect();
    } catch (error) {
      console.error("Sign-in error:", error);
    }
  };

  const signOut = async () => {
    try {
      if (userManager) {
        dispatch({ type: SIGN_OUT });
        setSession(null);
        await userManager.signoutRedirect();
      }
    } catch (error) {
      console.error("Sign-out error:", error);
    }
  };

  const handleCallback = async (isAuthenticated: boolean, user: AuthUser) => {
    if (isAuthenticated && user) {
      const accessToken = window.localStorage.getItem("accessToken");
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        const response = await axios.get(`${OPENID_SITE_URL}/api/user-info`);
        const user = response.data;

        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
        navigate("/");
      } else {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } else {
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  console.log("state", state);
  const profile = state.user || {};
  const user = profile
    ? {
        id: profile.userId || "", //map response
        name: profile.userName || "",
        avatar: profile.userImage || "",
        jobTitle: profile.userJobTitle || "",
        email: profile.email || "",
        location: profile.location || "",
        isAdmin: profile.isAdmin || false,
      }
    : null;

  const partner = profile.partners || [];

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "oidc",
        user,
        partner,
        signIn,
        signOut,
        handleCallback,
        resetPassword: (email: string) =>
          console.log("Reset password for:", email),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
